import React from "react"
import Seo from "../components/Seo"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({ data: { sectors } }) => {
  return (
    <>
      <Seo title="Adtrak Sector Hub" />

        <div className="container block p-8 my-16 md:p-16">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
            {sectors.edges.map(({ node }, index) => (
              <div key={index} className="">
                <div className="relative overflow-hidden">
                  <Link
                    to={`/sectors/${node.slug}/`}
                    className="relative block group"
                  >
                    {node.indexImage && <GatsbyImage className="w-full h-full bg-cover" image={node.indexImage.gatsbyImageData} alt={node.indexImage.alt ? node.indexImage.alt : node.title } />}

                    <div class="absolute bottom-0 left-0 bg-gradient-to-t from-secondary-dark w-full h-[50%]"></div>

                    <div className="absolute bottom-0 left-0 mb-6 ml-6 text-white transition duration-300 ease-in-out transform translate-y-16 group-hover:translate-y-0">
                      <span className="block text-lg font-display md:text-xl xl:text-2xl">
                        {node.title}
                      </span>
                      <span className="block font-sans transition duration-300 ease-in-out opacity-0 group-hover:opacity-100">
                        View Sector
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    sectors: allDatoCmsSector(sort: { fields: title, order: ASC }) {
      edges {
        node {        
          title
          slug
          indexImage {
            gatsbyImageData(layout: CONSTRAINED, width: 500, height: 500)
            alt
          }
        }
      }
    }
  }
`
